
import { css } from '@emotion/css'
import { IconDeviceFloppy } from '@tabler/icons-vue'
import {
  ArrowsLeftRight,
  FileImport,
  HandStop,
  Refresh,
  ReportAnalytics,
  Run,
  TableExport,
  Trash
} from '@vicons/tabler'
import { Icon } from '@vicons/utils'
import { computed, defineComponent, PropType, reactive } from 'vue'

import { Network } from '@/libs/bayes'
import { cssIcon } from '@/styles/common'

import { AnalysisTask } from '../../libs/common'

export const EVENTS = {
  ABORT: 'ABORT',
  EXPORT: 'EXPORT',
  IMPORT: 'IMPORT',
  RUN: 'RUN',
  REMOVE: 'REMOVE',
  REMOVE_ALL: 'REMOVE_ALL',
  RUN_ALL: 'RUN_ALL',
  REFRESH: 'REFRESH',
  UPDATE_TASKS: 'UPDATE_TASKS',
  SAVE_TASKS: 'SAVE_TASKS',
  VIEW_RESULT: 'VIEW_RESULT'
}

const wrapper = css`
  display: flex;
  flex-direction: column;

  .ant-table-body {
    .ant-btn {
      padding: 0 8px;
      height: auto;
    }

    td {
      height: 35px;
      padding: 5px 3px !important;
    }
  }
`

type TaskRowType = {
  key: string
  name: string
  networkName: string
  status: string
  task: AnalysisTask
}

interface LocalState {
  selectedRowKeys: string[]
}

export interface TaskListConfig {
  showResults?: boolean
  exportResults?: boolean
  updateTasks?: boolean
  importTasks?: boolean
  saveTasks?: boolean
  run?: boolean
  remove?: boolean
  hideNetwork?: boolean
}

export const TASK_LIST_CONFIG_DEFAULT = {
  showResults: true,
  exportResults: false,
  updateTasks: true,
  importTasks: false,
  saveTasks: false,
  run: false,
  remove: false,
  hideNetwork: false
}

export default defineComponent({
  components: {
    IconDeviceFloppy,
    ArrowsLeftRight,
    FileImport,
    HandStop,
    Icon,
    Refresh,
    ReportAnalytics,
    Run,
    TableExport,
    Trash
  },
  props: {
    networks: { type: Object as PropType<Network[]>, required: true },
    networkMap: { type: Object as PropType<Record<string, Network>>, required: true },
    tasks: { type: Object as PropType<AnalysisTask[]>, required: true },
    config: {
      type: Object as PropType<TaskListConfig>,
      default: () => ({ ...TASK_LIST_CONFIG_DEFAULT })
    }
  },
  emits: Object.values(EVENTS),
  setup(props, { emit }) {
    const state: LocalState = reactive({
      selectedRowKeys: []
    })

    const taskListConfig = computed(() =>
      Object.assign({ ...TASK_LIST_CONFIG_DEFAULT }, props.config)
    )

    const selectRow = (record: TaskRowType) => {
      const selectedRowKeys: string[] = [...state.selectedRowKeys]
      if (selectedRowKeys.indexOf(record.key) >= 0) {
        selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1)
      } else {
        selectedRowKeys.push(record.key)
      }
      state.selectedRowKeys = selectedRowKeys
    }

    const rowSelection = computed(() => {
      return {
        selectedRowKeys: state.selectedRowKeys,
        onChange: (selectedRowKeys: any) => {
          state.selectedRowKeys = selectedRowKeys
        }
      }
    })

    const customRow = (record: TaskRowType) => {
      return {
        onClick: () => {
          selectRow(record)
        }
      }
    }

    // const selectedRowKeys = ref<Key[]>([]) // Check here to configure the default column

    // const onSelectChange = (changableRowKeys: Key[]) => {
    //   console.log('selectedRowKeys changed: ', changableRowKeys)
    //   selectedRowKeys.value = changableRowKeys
    // }

    // const rowSelection = { selectedRowKeys: selectedRowKeys, onChange: onSelectChange }

    const taskColumns = computed(() => {
      let cols: any[] = [
        {
          title: 'Task',
          dataIndex: 'name',
          key: 'name'
        }
      ]
      if (!taskListConfig.value.hideNetwork) {
        cols.push({
          title: 'Network name',
          dataIndex: 'networkName',
          key: 'networkName'
        })
      }
      cols = cols.concat([
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status'
        },
        {
          title: '',
          key: 'actions',
          slots: {
            customRender: 'actions'
          }
        }
      ])
      return cols
    })

    const taskRows = computed(
      () =>
        props.tasks?.map((task: AnalysisTask, index: number) => ({
          key: task.id,
          name: task.name,
          index,
          networkName: props.networkMap[task.networkId].name,
          status: task.status,
          task
        })) || []
    )

    const refresh = () => {
      emit(EVENTS.REFRESH)
    }

    const run = (task: AnalysisTask) => {
      emit(EVENTS.RUN, task)
    }

    const abort = (task: AnalysisTask) => {
      emit(EVENTS.ABORT, task)
    }

    const remove = (record: any) => {
      emit(EVENTS.REMOVE, record.key, record.index)
    }

    const viewResult = (task: AnalysisTask) => {
      emit(EVENTS.VIEW_RESULT, task)
    }

    const updateTasks = () => {
      emit(EVENTS.UPDATE_TASKS)
    }

    const saveTasks = () => {
      emit(EVENTS.SAVE_TASKS)
    }

    const runAll = () => {
      emit(EVENTS.RUN_ALL)
    }

    const removeAll = () => {
      emit(EVENTS.REMOVE_ALL)
    }

    const exportResults = () => {
      emit(EVENTS.EXPORT)
    }

    const importTasks = () => {
      emit(EVENTS.IMPORT)
    }

    return {
      removeAll,
      saveTasks,
      importTasks,
      abort,
      cssIcon,
      customRow,
      exportResults,
      refresh,
      remove,
      rowSelection,
      run,
      runAll,
      taskColumns,
      taskListConfig,
      taskRows,
      updateTasks,
      viewResult,
      wrapper
    }
  }
})

import { InputType } from '@/components/analysis/libs/common'

export type JobInput = {
  networkKey?: string
  key: string
  state?: string
  value?: number
  values?: number[]
  variation?: number
  variations?: number[]
}

export type JobOutput = {
  networkKey?: string
  key: string
  utilityVector: number[]
  constraint?: number
}

export type JobInputNode = {
  networkKey?: string
  key: string
  state?: string
  value?: number
  values?: number[]
  variation?: number
  variations?: number[]
  type?: InputType
}

export type JobOutputNode = {
  networkKey?: string
  key: string
  utilityVector: number[]
  constraint?: number
  outcomes?: number[]
  value?: number
}

export type JobConfig = {
  optionDesignConfig?: Record<string, any>
  causeEffectConfig?: Record<string, any>
  monteCarloConfig?: Record<string, any>
  hybridConfig?: Record<string, any>
  smileConfig?: any
}

export type JobResult = {
  outputNodes?: JobOutputNode[]
  results?: any
  optionDesignResults?: any
  hybridResults?: any
}

export enum JobType {
  WHATIF = 'WHATIF',
  SENSITIVITY = 'SENSITIVITY',
  OPTIONDESIGN = 'OPTIONDESIGN',
  CAUSEEFFECT = 'CAUSEEFFECT',
  HYBRID = 'HYBRID',
  MONTECARLO = 'MONTECARLO'
}

export type JobTask = {
  id: string
  name?: string
  type: JobType
  networkId: string
  jobId: string
  runrJobId?: string
  status: string
  config?: JobConfig
  inputNodes: JobInputNode[]
  outputNodes: JobOutputNode[]
  result?: JobResult
  userId?: string
}

export type JobParams = {
  networkIds: string[]
  networkMap?: Record<string, string>
  config?: JobConfig
  inputs: JobInput[]
  outputs: JobOutput[]
  tasks?: JobTask[] // deprecated
}

export type Job = {
  id?: string
  type: JobType
  workspaceId: string
  userId: string
  sessionId?: string
  stats?: any
  taskCompletedCount?: number
  taskCount?: number
  params?: JobParams
  config?: JobParams // deprecated
  results?: JobResult[] // deprecated
}


import 'splitpanes/dist/splitpanes.css'

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  CloudOutlined,
  ExportOutlined,
  FormOutlined,
  PieChartOutlined,
  RadarChartOutlined
} from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { isArray } from 'lodash'
import { isEmpty } from 'lodash-es'
import { clone } from 'ramda'
import { Pane, Splitpanes } from 'splitpanes'
import { computed, defineComponent, reactive, Ref, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import UtilityVector from '@/components/analysis/common/UtilityVector.vue'
import { AnalysisTask, makeDefaultUtilityVector } from '@/components/analysis/libs/common'
import useMethods from '@/components/composables/methods'
import useStochasticResults from '@/components/composables/stochastics'
import useSurvey from '@/components/composables/survey'
import AceTable from '@/components/method-ace/AceTable.vue'
import CainTable from '@/components/method-cain/CainTable.vue'
import { TABLE_EVENTS } from '@/components/method-common/Table'
import MarginalTable from '@/components/method-marginal/MarginalTable.vue'
import SurveyDialog from '@/components/survey/SurveyDialog.vue'
import VariableTable from '@/components/variable/VariableTable.vue'
import { DB_ENUM_VALUES } from '@/constants/database'
import { EMIT_EVENTS } from '@/constants/emits'
import { MESSAGE } from '@/constants/message'
import { ROUTE_NAME } from '@/constants/router'
import { ModuleNames } from '@/constants/vuex'
import { CPTMethod } from '@/libs/bayes/enums/CPTMethod'
import { Variable } from '@/libs/bayes/Variable'
import { Dict } from '@/libs/common'
import { publishSurvey } from '@/services/composition/survey'
import { useStore } from '@/store'
import { SurveyActionEnum } from '@/store/enums/actions/survey'
import { UserStateEnum } from '@/store/enums/states/user'
import { vuexActions } from '@/store/util'
import { ResponseSchema, SurveyExportFormatEnum, User } from '@/types'

import StochasticApexChart from './StochasticApexChart.vue'
import StochasticSetup, { useStochasticSetup } from './StochasticSetup.vue'

export default defineComponent({
  components: {
    StochasticApexChart,
    Pane,
    Splitpanes,
    AceTable,
    CloudOutlined,
    PieChartOutlined,
    ExportOutlined,
    FormOutlined,
    MarginalTable,
    VariableTable,
    CainTable,
    RadarChartOutlined,
    StochasticSetup,
    SurveyDialog,
    UtilityVector
  },
  props: {
    workspaceId: { type: String, required: true },
    surveyId: { type: String, required: true }
  },
  setup(props) {
    const store = useStore()
    const route = useRoute()
    const routerParams = route.params
    const { workspaceId, surveyId } = routerParams
    const verboseState = ref(false)
    const panels = reactive({
      variables: true,
      survey: true,
      cpt: true
    })
    const outputMap: Dict = ref({})

    const userListContent = computed(() => store.state.user[UserStateEnum.USER_LIST]?.content)

    const { loadStochasticResults, stochasticResultMap } = useStochasticResults(
      props.workspaceId,
      props.surveyId
    )

    const {
      cpt,
      cptSet,
      persistedResponses,
      persistResponses,
      currentNetwork,
      currentSurvey,
      network,
      hasDiscreteScale,
      selectedVariable,
      selectVariable
    } = useSurvey(store, true)

    watch(currentSurvey, () => {
      updateOutputMap()
    })

    const updateOutputMap = () => {
      if (currentSurvey.value?.ext?.outputMap) {
        outputMap.value = clone(currentSurvey.value?.ext?.outputMap)
      }
    }
    updateOutputMap()

    const {
      isCain,
      isAceOriginal,
      isAceDst,
      isAceDstSimple,
      isAceDstFull,
      isMarginal
    } = useMethods(cpt)

    const isShowEditModal = ref(false)

    /**
     * Export CPT response for current survey
     * @param fileType - output file type
     **/
    const exportCPTResponse = (fileType: SurveyExportFormatEnum) => {
      if (surveyId && typeof surveyId === 'string' && !isEmpty(network.value.variables)) {
        store.dispatch(vuexActions(ModuleNames.SURVEY, SurveyActionEnum.EXPORT_SURVEY), {
          surveyId,
          format: fileType,
          userIds: userListContent.value.map((each: User) => each.id) as Array<User['id']>,
          variableIds: network.value.variables.map((each: Variable) => each.id)
        })
      }
    }

    /**
     * Handle display create user model
     **/
    const handleShowEditModal = () => {
      isShowEditModal.value = !isShowEditModal.value
    }

    // http://localhost:3000/#/workspaces/608798785a1e581fa9eace8c/surveys/6095ed100d4da47b36cdceda/questionnaire
    const getLink = () => {
      window.open(`/#/workspaces/${workspaceId}/surveys/${surveyId}/questionnaire`)
    }

    const persistCPT = async () => {
      try {
        if (currentNetwork.value && cptSet.value && !isArray(surveyId) && !isArray(workspaceId)) {
          await publishSurvey(currentNetwork.value, cptSet.value, surveyId, workspaceId)
          message.success(MESSAGE.PERSIST_CPT_SUCCESS)
        }
      } catch (err) {
        message.error(MESSAGE.PERSIST_CPT_FAIL)
        throw err
      }
    }

    const toggleCain = async ({ variable, method }: { variable: Variable; method: CPTMethod }) => {
      if (!currentSurvey.value) {
        return
      }
      const surveyData = clone(currentSurvey.value)
      surveyData.ext.collectionMethodMap = surveyData.ext.collectionMethodMap || {}
      surveyData.ext.collectionMethodMap[variable.id] = method
      const surveyId = currentSurvey.value.id
      await store.dispatch(vuexActions(ModuleNames.SURVEY, SurveyActionEnum.UPDATE_SURVEY), {
        id: surveyId,
        survey: {
          ...surveyData,
          workspaceId,
          surveyId
        }
      })
      // updateMethodForVariable(variable: Variable, method: CPTMethod)
      message.success(MESSAGE.SURVEY_CONFIG_UPDATE_SUCCESS)
    }

    const isOperating = ref(false)
    const onResponsesSave = async ({ responses }: { responses: Array<ResponseSchema> }) => {
      isOperating.value = true
      try {
        persistResponses(responses, true) // true for analytics/ stochastic
        message.success(MESSAGE.CPT_RESPONSES_SAVE_SUCCESS)
      } catch (err) {
        message.error(MESSAGE.CPT_RESPONSES_SAVE_FAIL)
        isOperating.value = false
        throw err
      }
      isOperating.value = false
    }

    const selectedVariableResponses = computed(() => {
      const variableId = selectedVariable.value?.id
      if (variableId) {
        return persistedResponses.value.filter(
          (response: ResponseSchema) => response.variableId === variableId
        )
      }
      return []
    })

    const loadResults = (raw: boolean, tasks_: AnalysisTask[]) => {
      loadStochasticResults(raw, tasks_)
    }

    const histData = computed(() => {
      if (stochasticResultMap.value && selectedVariable.value) {
        return stochasticResultMap.value[selectedVariable.value.name]
      }
      return null
    })

    const isChartVisible = ref(false)

    const showChart = () => {
      isChartVisible.value = true
    }

    const uvVariable: Ref<Variable | null> = ref(null)
    const isUvVisible = ref(false)
    const outputUv: Ref<number[] | null> = ref(null)
    const toggleOutput = async ({
      variable,
      isOutput
    }: {
      variable: Variable
      isOutput: boolean
    }) => {
      uvVariable.value = variable
      if (!isOutput) {
        saveToggleOutput(variable, null)
      } else {
        outputUv.value =
          currentSurvey.value?.ext.outputMap[variable.name]?.map(
            (x: string) => parseFloat(x) * 100.0
          ) ?? makeDefaultUtilityVector(variable.stateNum)
        isUvVisible.value = true
      }
    }
    const saveToggleOutput = async (variable: Variable, uv: number[] | null) => {
      if (!currentSurvey.value) {
        return
      }
      const surveyData = clone(currentSurvey.value)
      surveyData.ext.outputMap = surveyData.ext.outputMap || {}
      if (uv) {
        outputMap.value[variable.name] = true
        surveyData.ext.outputMap[variable.name] = uv.map((x) => (x / 100).toString())
      } else {
        outputMap.value[variable.name] = false
        delete surveyData.ext.outputMap[variable.name]
      }
      const surveyId = currentSurvey.value.id
      await store.dispatch(vuexActions(ModuleNames.SURVEY, SurveyActionEnum.UPDATE_SURVEY), {
        id: surveyId,
        survey: {
          ...surveyData,
          workspaceId,
          surveyId
        }
      })
      // updateMethodForVariable(variable: Variable, method: CPTMethod)
      message.success(MESSAGE.SURVEY_CONFIG_UPDATE_SUCCESS)
    }
    const onUvOK = () => {
      isUvVisible.value = false
      if (uvVariable.value && outputUv.value?.length) {
        saveToggleOutput(uvVariable.value, outputUv.value)
      }
    }

    return {
      outputUv,
      onUvOK,
      isUvVisible,
      histData,
      isChartVisible,
      showChart,
      stochasticResultMap,
      outputMap,
      loadResults,
      ...useStochasticSetup(),
      selectedVariableResponses,
      toggleOutput,
      TABLE_EVENTS,
      onResponsesSave,
      DB_ENUM_VALUES,
      EMIT_EVENTS,
      persistCPT,
      handleShowEditModal,
      isShowEditModal,
      isAceDst,
      isAceDstSimple,
      isAceDstFull,
      isAceOriginal,
      isCain,
      isMarginal,
      cpt,
      cptSet,
      hasDiscreteScale,
      currentSurvey,
      selectedVariable,
      getLink,
      toggleCain,
      selectVariable,
      exportCPTResponse,
      verboseState,
      network,
      panels,
      SurveyExportFormatEnum,
      ROUTE_NAME
    }
  }
})
